import { useMutation, useQuery } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { ToastContainer } from "./ToastContainer";

import { getFragmentData } from "src/gql/__generated__";
import {
  CompleteStaffCallRequestDocument,
  FetchCurrentShop_StaffCallRequestDocument as FetchCurrentShopDocument,
  Visit_StaffCallRequestFragment,
  Visit_StaffCallRequestFragmentDoc,
} from "src/gql/__generated__/graphql";

type StaffCallRequestProviderProps = {
  refetch: () => Promise<void>;
};

const context = createContext<StaffCallRequestProviderProps>({
  refetch: async () => {},
});

const { Provider } = context;

export const StaffCallRequestProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const toast = useToast({
    containerStyle: {
      mx: 4,
      mt: 0,
      mb: 4,
    },
  });

  const [visits, setVisits] = useState<Visit_StaffCallRequestFragment[]>([]);
  const { refetch } = useQuery(FetchCurrentShopDocument, {
    onCompleted: (data) => {
      setVisits(
        data.currentShop?.visits.map((visit) =>
          getFragmentData(Visit_StaffCallRequestFragmentDoc, visit),
        ) || [],
      );
    },
  });

  const [completeStaffCallRequest] = useMutation(
    CompleteStaffCallRequestDocument,
    {
      onCompleted: async () => {
        await refetch();
      },
    },
  );

  const handleComplete = useCallback(
    async (visitId: string) => {
      await completeStaffCallRequest({ variables: { input: { visitId } } });

      toast.close(visitId);
    },
    [completeStaffCallRequest, toast],
  );

  useEffect(() => {
    visits.forEach((visit) => {
      if (toast.isActive(visit.id)) return;

      toast({
        id: visit.id,
        duration: null,
        position: "bottom-right",
        render: () => (
          <ToastContainer
            visit={visit}
            onSubmit={() => handleComplete(visit.id)}
          />
        ),
      });
    });
  }, [handleComplete, toast, visits]);

  return (
    <Provider
      value={{
        refetch: async () => {
          toast.closeAll();
          const { data } = await refetch();
          setVisits(
            data.currentShop?.visits.map((visit) =>
              getFragmentData(Visit_StaffCallRequestFragmentDoc, visit),
            ) || [],
          );
        },
      }}
    >
      {children}
    </Provider>
  );
};

export const useStaffCallRequest = (): StaffCallRequestProviderProps => {
  const { refetch } = useContext(context);

  return {
    refetch,
  };
};
