import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import { BellRing } from "lucide-react";
import { useAsyncFn } from "react-use";

import { Visit_StaffCallRequestFragment } from "src/gql/__generated__/graphql";

type Props = {
  visit: Visit_StaffCallRequestFragment;
  onSubmit: () => Promise<void>;
};

export const ToastContainer = ({ visit, onSubmit }: Props) => {
  const [{ loading }, doSubmit] = useAsyncFn(onSubmit, [onSubmit]);

  return (
    <Box p={4} backgroundColor="red.50" w="300px" h="100px" borderRadius="4px">
      <HStack justifyContent="space-between" alignItems="flex-start" h="100%">
        <HStack alignItems="flex-start">
          <BellRing color="red" />

          <VStack spacing={0} alignItems="flex-start">
            <Text>スタッフ呼び出し</Text>
            <Text>
              {dayjs(visit.staffCallRequest?.createdAt).format("HH:mm")}
            </Text>
          </VStack>
        </HStack>

        <VStack justifyContent="space-between" alignItems="flex-end" h="100%">
          <Box>{visit.tableName}</Box>
          <Button
            variant="outline"
            size="sm"
            isLoading={loading}
            onClick={doSubmit}
          >
            対応完了
          </Button>
        </VStack>
      </HStack>
    </Box>
  );
};
